<template>
    <div id="s-purchasing-acp" class="s-purchasing-acp-v2">
        <template v-if="settings.mode === 'simple'">
            <s-products-simple
                :settings="settings"
                @changePeriod="changePeriod($event)"
                @changeAddon="changeAddon($event)"
                @changeQuantity="changeQuantity($event)"
                @toggleProduct="toggleProduct($event)"
                @cartRecommendation="cartRecommendation($event)"
            />
        </template>

        <template v-else-if="settings.mode === 'expert'">
            <s-products
                :settings="settings"
                @toggleCart="toggleCart($event)"
                @changeItem="changeItem($event)"
                @changeWorkload="changeWorkload($event)"
                @changeQuantity="changeQuantity($event)"
                @toggleProduct="toggleProduct($event)"
            />

            <s-features :settings="settings" />

            <s-comparison :settings="settings" />

            <section v-if="settings.page?.isVisibleFAQ && content.faq" class="s-purchasing-acp-faq">
                <s-faq class="s-purchasing-acp-faq" v-bind="content.faq" />
            </section>

            <s-cart
                v-if="isVisible.cart"
                :settings="settings"
                @changeItem="changeItem($event)"
                @toggleCart="toggleCart($event)"
                @toggleProduct="toggleProduct($event)"
                @changeQuantity="changeQuantity($event)"
                @changeAddon="changeAddon($event)"
                @cartRecommendation="cartRecommendation($event)"
            />

            <s-modal
                v-if="isVisible.modal"
                :settings="settings"
                @modalClose="modalClose()"
                @modalChangeProduct="modalChangeProduct()"
            />
        </template>

        <template v-else-if="settings.mode === 'addons'">
            <s-addons
                :settings="settings"
                @changePage="changePage($event)"
                @toggleProduct="toggleProduct($event)"
                @changeQuantity="changeQuantity($event)"
                @changeAddon="changeAddon($event)"
            />
        </template>
    </div>
</template>

<script>
import { merge } from 'lodash';
import { mapState } from 'vuex';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import currency from '@core/mixins/currency.js';
import purchasing from '@core/mixins/purchasing';
import SFaq from '@core/slices/pages/faq/faq.vue';
import SAddons from './addons.vue';
import SCart from './components/cart.vue';
import SComparison from './components/comparison.vue';
import SFeatures from './components/features.vue';
import SModal from './components/modal.vue';
import mixin from './mixin.js';
import SProductsSimple from './products-simple.vue';
import SProducts from './products.vue';

const STORE_SLICE_NAME = 's-purchasing-cyber-protect';

export default {
    name: 'SPurchasingVersion2',

    components: {
        SFaq,
        SAddons,
        SCart,
        SComparison,
        SFeatures,
        SModal,
        SProductsSimple,
        SProducts,
    },

    mixins: [mixin, analytics, purchasing, currency, cleverbridge],

    props: {
        page: {
            type: Object,
            default: null,
        },

        purchasePageCTA: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        coupon: '',
        licenses: [],
        products: [],
        workloads: [],
        isVisible: {
            cart: false,
            modal: false,
        },
        mode: 'expert',
        promo: {},
        isVisibleCloudLink: true,
        cartRecommendations: [],
    }),

    computed: {
        ...mapState({
            slice: (state) => state.slices.items[STORE_SLICE_NAME] || {},
        }),

        content() {
            return this.slice?.['version-2'] || {};
        },

        settings() {
            const page = { ...this.content?.page, ...this.page };
            return {
                licenses: this.licenses,
                workloads: this.workloads,
                products: this.products,
                mode: this.page?.mode || this.mode,
                promo: this.promo,
                page,
                dictionary: this.content.dictionary || {},
                features: page.isVisibleFeatures && this.content.features,
                comparison: page.isVisibleComparison && this.content.comparison,
                recommendations: this.slice.recommendations,
                isVisibleCloudLink: this.isVisibleCloudLink,
                cartRecommendations: this.cartRecommendations,
            };
        },
    },

    watch: {
        isVisible: {
            handler(value) {
                const className = 'body-overflow-hidden';
                if (Object.values(value).find(Boolean)) {
                    document.body.classList.add(className);
                    if (value.cart) {
                        this.addedLicenses
                            .filter((item) => item.workload)
                            .forEach((license) => this.sendEcommerceAdd(license));
                    }
                } else {
                    document.body.classList.remove(className);
                    this.addedLicenses
                        .filter((item) => item.workload)
                        .forEach((license) => this.sendEcommerceRemove(license));
                }
            },
            deep: true,
        },
        addedLicenses: {
            handler() {
                this.isVisibleCloudLink = !(this.hasMicrosoft365ID || this.hasGoogleWorkspaceID);

                const hasCloud = Boolean(this.licenses.filter((item) => item.quantity && item.cloud).length);
                if (this.isVisibleCloudLink || !hasCloud) return;

                this.licenses = this.licenses
                    .map((item) => ({
                        ...item,
                        quantity: item.quantity && (item.cloud || item.isExtension) ? 0 : item.quantity,
                    }));

                this.products = this.products
                    .map((item) => ({
                        ...item,
                        isOpened: item.product === 'cloud-storage' ? false : item.isOpened,
                    }));
            },
        },
    },

    created() {
        this.coupon = this.$route.query?.coupon || '';

        if (this.coupon && this.activeCoupon.discount) {
            const list = this.activeCoupon.cleverBridgeCartList || '';
            this.promo = {
                ...this.promo,
                ...this.activeCoupon,
                isPromoActive: true,
                cleverBridgeCartList: list.split(',').map(Number),
            };
        } else {
            const isPurchase = true;
            const cta = merge(this.productCTA({ isPurchase }), this.purchasePageCTA);
            const isPromoActive = this.checkPromo({ ...cta.promo, isPurchase });
            this.promo = { ...cta.promo, isPurchase, isPromoActive };
        }

        const workloads = this.content.workloads || [];
        this.workloads = workloads.map((item) => ({ selected: false, ...item }));

        const products = this.content.products || [];
        this.licenses = products
            .filter((item) => item.licenses)
            .flatMap((item) => item.licenses.map((license) => ({
                ...license,
                quantity: 0,
                quantityMax: license.quantityMax || 99,
                multiplier: license.multiplier || 1,
                store: this.currentData(license.cleverBridgeCartID),
                product: item.product,
            })));

        this.products = products.map((product) => {
            const list = this.licenses.filter((item) => item.product === product.product && !item.isExtension);
            const prices = list
                .map((item) => item.store.price)
                .filter(Boolean);

            const pricesPerYear = list
                .filter((item) => item?.store?.price)
                .map((item) => (item.store.price) / (item?.period || 1));

            const filtered = list.filter((item) => item?.store?.price && item?.store?.discount_absolute);
            const fromFull = filtered.map((item) => (item.store.price + item.store.discount_absolute));
            const pricesPerYearFull = filtered.map((item) => (item.store.price + item.store.discount_absolute) / (item?.period || 1));

            const hasAddedLicense = list.find((item) => item.quantity);
            return {
                ...product,
                isOpened: Boolean(hasAddedLicense),
                from: prices.length ? Math.min(...prices) : 0,
                fromFull: fromFull.length ? Math.min(...fromFull) : 0,
                fromPerYear: pricesPerYear.length ? Math.min(...pricesPerYear) : 0,
                fromPerYearFull: pricesPerYearFull.length ? Math.min(...pricesPerYearFull) : 0,
            };
        });

        if (this.$route.query?.addons === '1' || this.settings.page.isAddons) {
            this.mode = 'addons';
        }
    },

    beforeMount() {
        this.init();
        this.ecommerceSendImpressions();
    },

    methods: {
        openProduct(productName) {
            this.settings.products.find((item) => item.product === productName).isOpened = true;
        },

        initDisasterRecovery(value, licenses, productName) {
            const license = licenses.find((item) => item.size === value);
            if (!license) return;

            this.isVisible.cart = true;
            this.openProduct(productName);
            license.quantity = 1;
        },

        initOnboardingService(value, licenses, productName) {
            const license = licenses[0];
            const quantity = Number(value.match(/q(\d*):?/)?.[1]);
            if (!license || quantity < 1) return;

            this.isVisible.cart = true;
            this.openProduct(productName);
            license.quantity = this.licenseQuantity(quantity, license);
        },

        initCloudStorage(value, licenses, productName) {
            this.isVisible.cart = true;
            const period = value.match(/p(\d):?/)?.[1];
            const cloud = value.match(/c(\d*):?/)?.[1];
            const pds = value.match(/:(pds)/)?.[1];
            const extension = this.settings.licenses.find((item) => item.isExtension);
            const license = licenses.find((item) => item.period === Number(period) && item.cloud === Number(cloud));
            if (license) {
                license.quantity = 1;
                this.openProduct(productName);
            }
            if (extension && pds) {
                extension.quantity = 1;
            }
        },

        initProductGeneric(value, workload) {
            const current = value.split(':');
            const abbr = current[0];
            const period = Number(value.match(/p(\d)/)?.[1]);
            const quantity = value.match(/q(\d*):?/)?.[1];

            const comparison = (item) => item.product === abbr && item.period === period && item.workload === workload;
            const license = this.settings.licenses.find(comparison);
            if (!license || !period || !quantity) return;

            this.changeItem(license);
            this.changeQuantity({
                ...license,
                isChecked: true,
                quantity: Number(license.quantity) + Number(quantity),
            });
            this.isVisible.cart = true;
        },

        init() {
            this.searchParams = new URL(document.location).searchParams;
            const search = this.searchParams.get('products');
            const products = search?.split(';').reverse();

            if (!search || !products) return;

            products.forEach((product) => {
                const productName = product.replace(/\(.*/, '');
                const value = product.replace(/.*\((.*)\)/, '$1');
                const licenses = this.settings.licenses.filter((item) => item.product === productName);

                switch (productName) {
                    case 'disaster-recovery':
                        this.initDisasterRecovery(value, licenses, productName);
                        break;
                    case 'onboarding-service':
                        this.initOnboardingService(value, licenses, productName);
                        break;
                    case 'cloud-storage':
                        this.initCloudStorage(value, licenses, productName);
                        break;
                    default:
                        this.initProductGeneric(value, productName);
                        break;
                }
            });
        },

        showModalUpgrade(product) {
            if (!product === 'acps') return;
            if (this.isVisible.cart) return;

            const hasSelected = this.licenses.find((item) => item.product === 'acpa' && item.quantity) || null;
            if (hasSelected && this.currentPeriod('acps') !== this.currentPeriod('acpa')) return;

            const modal = this.products.find((item) => item.product === 'acps').modal;
            if (!modal) return;

            const quantity = this.licenses
                .filter((license) => license.product === 'acps' && license.quantity)
                .map((license) => {
                    const comparison = (item) => item.product === 'acpa' && item.workload === license.workload;
                    const hasACPA = this.licenses.find(comparison);
                    return hasACPA ? license.quantity : 0;
                })
                .reduce((a, b) => a + b, 0);

            if (quantity < modal.maxQuantity) return;

            this.isVisible.modal = true;
        },

        toggleCart(product) {
            this.showModalUpgrade(product);

            if (this.isVisible.modal) return;

            this.isVisible.cart = !this.isVisible.cart;
        },

        toggleProduct(current) {
            if (!current) {
                return;
            }

            this.products = this.products.map((item) => ({
                ...item,
                isOpened: item.product === current.product ? !item.isOpened : item.isOpened,
            }));

            const comparison = (item) => item.quantity && item.product === current.product;

            if (current.isOpened) {
                this.sendEcommerceRemove(this.settings.licenses.find(comparison));

                this.licenses = this.licenses.map((item) => ({
                    ...item,
                    quantity: item.product === current.product ? 0 : item.quantity,
                }));
                return;
            }

            this.licenses = this.licenses.map((item) => {
                const isProduct = item.product === current.product;
                const isMinPrice = item.store.price === current.from;
                return {
                    ...item,
                    quantity: Number(isProduct && isMinPrice) || item.quantity,
                };
            });

            this.sendEcommerceAdd(this.settings.licenses.find(comparison));
        },

        currentData(cleverBridgeCartID) {
            return this.licenseData({ promo: this.promo, cleverBridgeCartID });
        },

        changeWorkload(name) {
            this.workloads = this.workloads.map((item) => ({
                ...item,
                selected: item.name === name && !item.selected,
            }));

            const selected = this.workloads.find((item) => item.selected);
            const workload = selected?.name || '';
            const productName = selected?.recommended || '';
            const period = this.currentPeriod(productName);

            this.licenses = this.licenses.map((item) => {
                const isActive = item.workload === workload && item.product === productName && item.period === period;
                return {
                    ...item,
                    isChecked: Boolean(isActive),
                    quantity: !item.workload ? item.quantity : Number(isActive),
                };
            });
        },

        changePeriod(current) {
            const comparison = (i) => i.workload === current.workload && i.product === current.product;
            const quantity = this.addedLicenses.find(comparison).quantity;
            this.licenses = this.licenses.map((item) => {
                if (item.product !== current.product) return item;
                if (item.workload !== current.workload) return item;
                if (item.period === current.period) {
                    const license = { ...item, quantity };
                    if (this.settings.mode === 'simple') this.sendEcommerceAdd(license);
                    return license;
                }
                return { ...item, quantity: 0 };
            });
        },

        changeItem(current) {
            const product = current.product;
            const period = this.currentPeriod(product);
            this.licenses = this.licenses.map((item) => {
                if (item.product !== product) return item;
                if (item.period !== current.period) return { ...item, quantity: 0, isChecked: false };
                const comparison = (i) => i.workload === item.workload && i.product === item.product && i.period === period;
                const license = this.licenses.find(comparison);
                return { ...item, quantity: license.quantity, isChecked: license.isChecked || false };
            });
            this.products.find((item) => item.product === product).selected = current.period;
        },

        licenseQuantity(quantity, license) {
            if (license.quantityMax < quantity) return license.quantityMax;
            return quantity;
        },

        changeQuantity(current) {
            const product = current.product;

            if (['cloud-storage', 'onboarding-service'].includes(product)) {
                const comparisonCloud = (item) => item.isExtension;
                const comparisonService = (item) => item.product === product;
                const license = this.licenses.find(product === 'cloud-storage' ? comparisonCloud : comparisonService);
                this.sendEcommerceRemove(license);
                license.quantity = current.quantity;
                this.sendEcommerceAdd(license);
                return;
            }

            const workload = current.workload;
            const period = current.setPeriod || this.currentPeriod(product);
            const comparison = (item) => item.workload === workload && item.product === product && item.period === period;
            const license = this.licenses.find(comparison);

            license.quantity = this.licenseQuantity(current.quantity, license);
            license.isChecked = current.isChecked;

            if (current.quantity < 1) {
                this.settings.workloads.find((item) => item.name === workload).selected = false;
            }
        },

        changeAddon(license) {
            const type = license.type;
            const quantity = license[type];
            const productName = license.product;
            const comparison = (item) => item.product === productName && item.quantity;

            if (license.cloud && license.period) {
                const selected = {};
                const current = this.settings.licenses.find(comparison);
                if (type === 'cloud') {
                    selected.period = license.setPeriod || current?.period || 1;
                    selected.cloud = quantity;
                }

                if (type === 'period') {
                    selected.cloud = current?.cloud || 250;
                    selected.period = quantity;
                }

                this.licenses = this.licenses.map((item) => {
                    if (item.product !== productName || item.isExtension) return item;
                    return {
                        ...item,
                        quantity: Number(item.period === selected.period && item.cloud === selected.cloud),
                    };
                });
                if (this.settings.mode === 'simple') return;
                this.sendEcommerceRemove(current);
                this.sendEcommerceAdd(this.settings.licenses.find(comparison));
                return;
            }

            this.sendEcommerceRemove(this.settings.licenses.find(comparison));
            this.licenses = this.licenses.map((item) => {
                if (item.product !== productName) return item;
                return { ...item, quantity: Number(item[type] === quantity) };
            });
            this.sendEcommerceAdd(this.settings.licenses.find(comparison));
        },

        modalClose() {
            this.isVisible.modal = false;
            this.isVisible.cart = true;
        },

        modalChangeProduct() {
            this.isVisible.modal = false;

            const selected = this.licenses.find((item) => item.product === 'acps' && item.quantity);
            this.changeItem({ period: selected.period, product: 'acpa' });

            this.licenses = this.licenses.map((license) => {
                if (license.product !== 'acpa') return license;
                const period = license.period;
                const workload = license.workload;
                const comparison = (item) => item.product === 'acps' && item.period === period && item.workload === workload;
                const quantity = this.licenses.find(comparison).quantity;
                return {
                    ...license,
                    isChecked: Boolean(license.quantity || quantity),
                    quantity: license.quantity + quantity,
                };
            });

            this.licenses = this.licenses.map((license) => {
                if (license.product !== 'acps') return license;
                const comparison = (item) => item.product === 'acpa' && item.workload === license.workload;
                const hasACPA = this.licenses.find(comparison);
                return {
                    ...license,
                    isChecked: hasACPA && license.quantity ? false : Boolean(license.quantity),
                    quantity: hasACPA ? 0 : license.quantity,
                };
            });

            this.workloads = this.workloads.map((item) => ({ ...item, selected: false }));
            this.isVisible.cart = true;
        },

        changePage(name) {
            this.mode = name;
            window.scrollTo(0, 0);
        },

        cartRecommendation(cart) {
            if (cart.remove) {
                const comparison = (item) => item !== cart.remove.cleverBridgeCartID;
                this.cartRecommendations = this.cartRecommendations.filter(comparison);
                return;
            }
            this.cartRecommendations.push(cart.add.cleverBridgeCartID);
        },
    },
};
</script>

<style lang="postcss">
.body-overflow-hidden {
    overflow: hidden;

    #sfchat-iframe {
        display: none;
    }
}
</style>
