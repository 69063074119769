<template>
    <div class="s-slice s-product-demo-modal">
        <div class="container a-container">
            <div class="main">
                <a-label v-if="label" :type="labelType" :variant="labelVariant">
                    {{ label }}
                </a-label>
                <h2 v-if="title" class="title">
                    {{ title }}
                </h2>
                <a-dangerous-html v-if="lead" class="lead" :content="lead" />
                <a-dangerous-html v-if="description" class="description" :content="description" />
                <ul v-if="bulletListItems.length" class="bullets-list">
                    <li v-for="item in bulletListItems" :key="item" class="bullets-item">
                        <a-dangerous-html class="bullets-item-text" :content="item" />
                    </li>
                </ul>
            </div>
            <div class="media">
                <div class="screenshot-wrapper">
                    <a-picture
                        :is-shadow="false"
                        v-bind="productImage"
                        class="screenshot-image"
                    />
                </div>
                <div
                    ref="ctaBlock"
                    v-modal="id"
                    class="cta-block"
                    @click="onModalOpen"
                    @close="stopTracking"
                >
                    <a-button
                        ref="ctaButton"
                        class="cta-button"
                        v-bind="ctaSettings"
                    />
                </div>
            </div>

            <!-- <s-message-highlights
                :title="title"
                :lead="lead"
                :bullet-list-items="bulletListItems"
                :media="media"
                @click="onModalOpen"
            /> -->

            <!--
            <a-button
                v-modal="id"
                :text="buttonTitle"
                type="action"
                @click="onModalOpen"
                @close="stopTracking"
            /> -->

            <a-modal :id="id" type="modal">
                <div class="modal-contents" :style="modalStyle">
                    <div
                        v-if="!iframeLoaded"
                        v-loading="true"
                        class="loader"
                        el-loading-size="48"
                        el-loading-color="brand-secondary"
                    />
                    <iframe
                        v-if="modalOpened"
                        ref="iframe"
                        :src="figmaSrc"
                        allowfullscreen
                        class="iframe"
                    />
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALabel from '@core/components/label/label.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'SProductDemoModal',

    components: {
        AModal,
        AButton,
        ADangerousHtml,
        ALabel,
        APicture,
    },

    directives: {
        Modal,
        Loading,
    },

    props: {
        /**
         * Figma Source
         */
        figmaSrc: {
            type: String,
            required: true,
        },
        /**
         * Label
         */
        label: {
            type: String,
            default: undefined,
        },
        labelVariant: {
            type: String,
            required: false,
            default: 'info',
        },
        labelType: {
            type: String,
            default: 'label',
        },
        /**
         * Title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Lead
         */
        lead: {
            type: String,
            default: undefined,
        },
        /**
         * Description
         */
        description: {
            type: String,
            default: undefined,
        },
        /**
         * Bullet list items
         */
        bulletListItems: {
            type: Array,
            default: () => [],
        },
        /**
         * Image thumbnail for modal
         */
        productImage: {
            type: Object,
            required: true,
        },
        /**
         * Aspect ration for modal contents
         */
        modalAspectRatio: {
            type: Number,
            default: 16 / 9,
        },
        /**
         * CTA button settings
         */
        cta: {
            type: Object,
            required: true,
        },
        /**
         * GA events
         */
        clickEvent: {
            type: Object,
            default: () => ({}),
        },
        openEvent: {
            type: Object,
            default: () => ({}),
        },
        closeEvent: {
            type: Object,
            default: () => ({}),
        },
        timeOnDemoEvent: {
            type: Object,
            default: () => ({}),
        },
    },

    data: () => ({
        id: 'figma-demo',
        modalOpened: false,
        iframeLoaded: false,
        startTime: null,
    }),

    computed: {
        modalStyle() {
            return {
                'aspect-ratio': this.modalAspectRatio,
            };
        },

        ctaSettings() {
            const fallback = {
                type: 'main',
                size: 's',
                glyph: 'i-chevron-big-right--16',
            };
            return { ...fallback, ...this.cta };
        },
    },

    beforeDestroy() {
        this.stopTracking();
    },

    methods: {
        async onModalOpen() {
            this.modalOpened = true;
            this.sendDataLayerEvent(this.clickEvent);
            await this.$nextTick();

            if (this.iframeLoaded) {
                this.initTracking();
                return;
            }

            this.$refs.iframe.onload = () => {
                this.iframeLoaded = true;
                this.initTracking();
            };
        },

        initTracking() {
            this.startTime = new Date();
            this.sendDataLayerEvent(this.openEvent);
        },

        stopTracking() {
            this.sendDataLayerEvent(this.closeEvent);
            this.sendTimeOnIframe();
        },

        sendTimeOnIframe() {
            const endTime = new Date();
            const timeOnIframe = endTime - this.startTime;
            const seconds = timeOnIframe / 1000;

            const timeOnDemoText = (s) => {
                if (s < 10) return '0–10 seconds';
                if (s < 30) return '11–30 seconds';
                if (s < 60) return '31–60 seconds';
                if (s < 180) return '1-3 minutes';
                if (s < 300) return '3-5 minutes';
                if (s < 600) return '5-10 minutes';
                if (s < 900) return '10-15 minutes';
                if (s < 1200) return '15-20 minutes';
                if (s < 1800) return '20-30 minutes';
                return '30+ minutes';
            };

            this.sendDataLayerEvent({
                ...this.timeOnDemoEvent,
                label: 'Time on Demo',
                context: timeOnDemoText(seconds),
            });
        },
        sendDataLayerEvent(event) {
            const e = {
                event: 'Acronis',
                eventCategory: 'Interactions',
                ...event,
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(e);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-demo-modal {
    padding: 64px 0;
    background: var(--av-solid-brand-lightest);
}

.container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}

.main {
    @mixin colls 12;
    @media (--viewport-desktop) {
        @mixin colls 6;
    }
    @media (--viewport-desktop) {
        padding-inline-end: 32px;
        margin-bottom: 0;
    }
}

.media {
    position: relative;
    display: block;
    margin-top: 56px;
    @mixin colls 12;

    @media (--viewport-mobile-wide) {
        @mixin colls 8;
        margin: 56px auto 0;
    }

    @media (--viewport-desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        @mixin colls 6;
        margin: 0;
    }

    cursor: pointer;

    &:hover {
        .screenshot-wrapper {
            box-shadow: none;
            opacity: 0.6;
        }

        .a-picture {
            transform: scale(1.02);
        }

        .cta-button {
            box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
        }
    }
}

.a-label {
    margin-bottom: 4px;
}

.title {
    @mixin display-accent;
    color: var(--av-nav-primary);
    margin-bottom: 24px;
}

.lead {
    @mixin lead;
    color: var(--av-nav-primary);
    margin-bottom: 24px;

    @media (--viewport-mobile-wide) {
        @mixin title;
    }
}

.description {
    @mixin paragraph;
    color: var(--av-fixed-secondary);
    margin-bottom: 24px;
}

.bullets-list {
    list-style: disc;
    list-style-position: inside;
}

.bullets-item-text {
    @mixin paragraph;
    color: var(--av-nav-primary);
    display: inline;
}

.screenshot-image {
    border-radius: 4px;
    border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
    box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
    transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    will-change: opacity;
    overflow: hidden;
}

.cta-block {
    position: absolute;
    inset: 0;
    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    background: radial-gradient(50.09% 77.73% at 50.09% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);

    &:deep(.a-button__content) {
        user-select: none;
    }
}

.cta-button {
    transition: box-shadow ease-in-out 0.2s;
}

.screenshot-wrapper {
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
    box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
    transition: opacity .2s ease-in-out, box-shadow .2s ease-in-out;
    will-change: opacity;
}

.a-picture {
    transition: transform 0.2s ease-in-out;
    will-change: transform;
}

.a-modal:deep(.content),
.a-modal:deep(.inner) {
    height: 100%;
    max-width: 1440px;
    @media (max-width: 1023px) {
        width: 100%;
    }
}

.loader {
    top: 50%;
    margin-top: -24px;
    height: 48px;
}

.iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.modal-contents {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.overlay {
    pointer-events: none;
    width: 100%;
    height: 100%;

    &.active {
        pointer-events: all;
    }
}
</style>
